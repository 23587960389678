import React, { useState } from "react"
import TemplateForm from "./TemplateForm"
import TemplateViewer from "./TemplateViewer"
const TemplateEditor = ({Cropeiagmefile}) => {
  const [templateFile, setTemplateFile] = useState(null);
  const [userData, setUserData] = useState({});

  const handleFormSubmit = (file, formData) => {
    console.log(file)
    setTemplateFile(file);
    setUserData(formData);
  };



  return (<>
    <TemplateForm onSubmit={handleFormSubmit} />
    <TemplateViewer templateFile={templateFile} Cropeiagmefile={Cropeiagmefile}  userData={userData} />


  </>
  )
}


export default TemplateEditor;