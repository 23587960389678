import React, { useState } from 'react';
import pdfFile from "../../Assets/PDF1.pdf";  // Import the PDF file from assets

const TemplateForm = ({ onSubmit }) => {
    const [formData, setFormData] = useState({});
    const Fakedata = {
        Previewsssss: {
            Name: "NIrakar",
            Birthday: "10/09/1997",
            Color: "Fair",
            Cplex: "NIrakar",
            avasfwe: "10/09/1997",
            vsdfer: "Fair",
            vasfwervb: "NIrakar",
            oinavaiefa: "10/09/1997",
        },
        Previe: {
            fatherName: "Fadfafaf",
            MotherName: "dfgasdfa",
        },
    };

    const handleChange = e => {
        setFormData(Fakedata);
    };

    const handleSubmit = e => {
        e.preventDefault();
        if (pdfFile) {
            onSubmit(pdfFile, Fakedata);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <button type="submit" disabled={!pdfFile}>Generate Template</button>
        </form>
    );
};

export default TemplateForm;
